import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { format } from 'date-fns'
import Utils from 'Utils'

import { Row } from 'Components/UI/Flex'

import { useAppContext } from 'Hooks'

import { COMMUNITY_PEOPLE } from 'Router/routes'

import UpdateNotificationsMutation from './Queries/UpdateNotifications.graphql'
import {
  getNotificationStatusDisplayName,
  getTaskKindDisplayName,
  TaskKind,
} from './getDisplayName'
import * as Styled from './Notifications.styles'

interface Props {
  isActive: boolean
  notification: MainSchema.Notification
  setViewingNotification: (n: MainSchema.Notification | null) => void
}

export default function Notifications(props: Props) {
  const { isActive, notification, setViewingNotification } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [updateNotifications] = useMutation(UpdateNotificationsMutation)
  const { slug } = useAppContext()
  const navigate = useNavigate()

  const handleClose = () => setViewingNotification(null)

  const handleViewDetails = () => {
    navigate(
      Utils.URL.buildFrontendPath({
        path: Utils.URL.generatePath(COMMUNITY_PEOPLE, { slug }),
        queryParams: { taskId: notification.task?.id ?? '' },
      }),
    )
  }

  useEffect(() => {
    if (isActive && notification?.id) {
      updateNotifications({
        variables: {
          notificationIds: [notification.id],
          read: true,
        },
      })
    }
  }, [isActive, notification?.id, updateNotifications])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setViewingNotification(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setViewingNotification])

  if (!isActive || notification === null) {
    return null
  }

  const formattedCompletedAt = format(
    notification.task?.completedAt ?? '0',
    'MMMM dd, yyyy h:mm a',
  )
  const formattedStartedAt = format(
    notification.task?.startedAt ?? '0',
    'MMMM dd, yyyy h:mm a',
  )

  const processedCount = notification.task?.metadata?.processedCount
  const fields = notification.task?.metadata?.fields

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Hdg>
        {getTaskKindDisplayName(notification.task?.kind as TaskKind)}
        <Styled.CloseIcon onClick={handleClose} />
      </Styled.Hdg>
      <Styled.Inner>
        <Styled.List>
          {notification.creator && (
            <Styled.Item>
              <Styled.Desc>Source</Styled.Desc>
              <div>
                {notification.creator.profile?.firstName}{' '}
                {notification.creator.profile?.lastName}
              </div>
            </Styled.Item>
          )}
          <Styled.Item>
            <Styled.Desc>Type</Styled.Desc>
            <div>{notification.kind}</div>
          </Styled.Item>
          <Styled.Item>
            <Styled.Desc>Date & Time</Styled.Desc>
            <div>{formattedCompletedAt ?? formattedStartedAt}</div>
          </Styled.Item>
          <Styled.Item>
            <Styled.Desc>Status</Styled.Desc>
            <div>
              {getNotificationStatusDisplayName(notification.task?.status)}
            </div>
          </Styled.Item>
        </Styled.List>
        {[processedCount, fields].some(Boolean) && (
          <>
            <Styled.SubHdg>Imported Data</Styled.SubHdg>
            <Styled.List>
              {processedCount > 0 && (
                <Styled.Item>
                  <Styled.Desc>Number of Records</Styled.Desc>
                  <div>{processedCount}</div>
                </Styled.Item>
              )}
              {fields && fields.length > 0 && (
                <></>
                // <Styled.Item>
                //  <Styled.Desc>Fields Imported</Styled.Desc>
                //  <div>{fields.join(', ')}</div>
                // </Styled.Item>
              )}
            </Styled.List>
          </>
        )}
      </Styled.Inner>
      <Row>
        <Styled.Btn onClick={handleViewDetails}>View Details</Styled.Btn>
        <Styled.Btn onClick={handleClose}>Close</Styled.Btn>
      </Row>
    </Styled.Wrapper>
  )
}
