import { useEffect, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import notificationsQuery from 'GraphQL/Queries/Community/notifications.graphql'

import { NotificationKind } from 'Constants/mainGraphQL'

import { useCommunity } from 'Hooks'

import communityUserQuery from './communityUserQuery.graphql'

export function useTaskUsers(taskId: string | null, communityIds: string[]) {
  const [taskUsers, setTaskUsers] = useState<MainSchema.CommunityUser[]>([])
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  const { community } = useCommunity()

  useEffect(() => {
    if (!taskId || !communityIds.length) return

    async function getTaskUsers() {
      setLoading(true)

      try {
        const { data: notificationsData } = await client.query({
          query: notificationsQuery,
          variables: {
            communityId: community?.id,
            kind: NotificationKind.Task,
            limit: 1000,
          },
          fetchPolicy: 'no-cache',
        })

        const notifications =
          (
            notificationsData.notifications as {
              rows: MainSchema.Notification[]
            }
          ).rows || []

        const taskNotification = notifications.find(n => n.task?.id === taskId)

        if (taskNotification) {
          const entityIds = ((
            taskNotification.task?.metadata as { entityIds?: string[] }
          )?.entityIds || []) as string[]

          const communityUsers = await Promise.all(
            entityIds.map(id =>
              client
                .query<
                  Pick<MainSchema.Query, 'getCommunityUser'>,
                  MainSchema.QueryGetCommunityUserArgs
                >({
                  query: communityUserQuery,
                  variables: { communityIds, communityUserId: id },
                  fetchPolicy: 'no-cache',
                })
                .then(r => r.data.getCommunityUser),
            ),
          )

          setTaskUsers(
            communityUsers.filter((user): user is MainSchema.CommunityUser =>
              Boolean(user),
            ),
          )
        } else {
          setTaskUsers([])
        }
      } catch {
        // fail silently
      } finally {
        setLoading(false)
      }
    }

    getTaskUsers()
  }, [taskId, communityIds, client, community?.id])

  return { taskUsers, loading }
}
