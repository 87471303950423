import React from 'react'

import { PROFILE_QUICK_ACTION_KINDS } from 'Features/Graph/Components/QuickActions/constants'
import useActionHandlers from 'Features/Graph/Components/QuickActions/useActionHandlers'
import Utils from 'Utils'

import { utils } from 'Components/Blocks/Graph'
import QuickActions from 'Components/Blocks/QuickActions/ActionOptions'
import { Avatar, Column, Icon, Row, Text } from 'Components/UI'
import { ConditionalLink } from 'Components/UI/_v2'

import useGraphContext from 'Hooks/useGraphContext'

import colors from 'Theme/_v2/colors'

import * as Styled from './ProfileHeader.styles'

export interface IProfileCardProps {
  communityUser: MainSchema.CommunityUser
}

function ProfileCard({ communityUser }: IProfileCardProps) {
  const { quickActions } = useGraphContext()

  const actionHandlers = useActionHandlers({
    quickActions,
  })

  const availableQuickActionsForUsers = communityUser
    ? utils.getAvailableQuickActionsForUsers(
        quickActions,
        actionHandlers,
        [communityUser],
        [communityUser],
      )
    : {}

  const userAvailableQuickActions =
    availableQuickActionsForUsers[communityUser?.id || 'none'] || {}

  const userQuickActions = utils.getEnabledQuickActions(
    PROFILE_QUICK_ACTION_KINDS,
    userAvailableQuickActions,
  )

  const avatar = communityUser?.photoUrl
  const showLinkedInUrl = !!communityUser?.linkedInUrl
  const showTwitterUrl = !!communityUser?.twitterUrl
  const showFacebookUrl = !!communityUser?.facebookUrl

  const jobTitle = React.useMemo(
    () => Utils.User.getCurrentJobTitleLabel(communityUser),
    [communityUser],
  )
  const organization = React.useMemo(
    () => Utils.User.getCurrentOrganizationNameLabel(communityUser),
    [communityUser],
  )
  const name = React.useMemo(
    () => Utils.User.getFullName(communityUser),
    [communityUser],
  )

  return (
    <Styled.NameHolder>
      <Avatar large mr={3} src={avatar} />

      <Column flex={1} fullWidth>
        <Text header header2 mb={1}>
          {name}
        </Text>

        <Text bodyMedium mb={1}>
          {jobTitle && `${jobTitle} `}
          {organization && `at ${organization}`}
        </Text>

        <Row alignItems="center" gap={3}>
          <ConditionalLink
            condition={showLinkedInUrl}
            href={communityUser.linkedInUrl}
            isExternal
            target="_blank"
          >
            <Icon.LinkedIn
              color={!showLinkedInUrl ? colors.social.disabled : undefined}
              size={20}
            />
          </ConditionalLink>

          <ConditionalLink
            condition={showTwitterUrl}
            href={communityUser.twitterUrl}
            isExternal
            target="_blank"
          >
            <Icon.Twitter
              color={!showTwitterUrl ? colors.social.disabled : undefined}
              size={20}
            />
          </ConditionalLink>

          <ConditionalLink
            condition={showFacebookUrl}
            href={communityUser.facebookUrl}
            isExternal
            target="_blank"
          >
            <Icon.Facebook
              color={!showFacebookUrl ? colors.social.disabled : undefined}
              size={20}
            />
          </ConditionalLink>
        </Row>
      </Column>

      {userQuickActions.length > 0 && (
        <QuickActions dropdown quickActions={userQuickActions} />
      )}
    </Styled.NameHolder>
  )
}

export default ProfileCard
