import React, { useCallback, useMemo } from 'react'

import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { useMutation } from '@apollo/client'
import { IconX } from '@tabler/icons-react'
import createProposalMutation from 'Features/ProfilePanel/Mutations/createProposal.graphql'
import deleteProposalMutation from 'Features/ProfilePanel/Mutations/deleteProposal.graphql'

import uniqBy from 'lodash/uniqBy'

import { Dialog } from 'Components/Blocks/Modals'
import { resetButton } from 'Components/Styles'
import { Column, Loader, Row } from 'Components/UI'
import { ConditionalLink, Link, Text } from 'Components/UI/_v2'

import { ProposalKind } from 'Constants/mainGraphQL'

import { useAppContext, useCommunity, useEntityModal } from 'Hooks'
import usePhoneNumber from 'Hooks/usePhoneNumber'

import EventBus from 'Services/EventBus'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import SectionTitle from './SectionTitle/SectionTitle'

import ShowMore from '../Buttons/ShowMore'
import Card from '../Card'
import { IconContacts } from '../icons.styles'
import InlineTextboxCreate from '../InlineTextboxCreate/InlineTextboxCreate'

export const Container = styled.div`
  display: flex;
  gap: ${themeGet('space.2')}px;
`

export const CloseIcon = styled(IconX)`
  width: 12px;
  height: 12px;
  align-self: center;
  color: ${colors.icon.profile};
`

export const Button = styled.button`
  ${resetButton};

  display: flex;
  cursor: pointer;
`

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

const PRIVATE = 'Private'

export interface IProposal {
  id?: string
  kind: ProposalKind
  name?: string
}

export interface IContactEmail {
  id?: string
  entityId?: string
  email?: string
  isProposal?: boolean
  isMyProposal: boolean
}

export interface IContactPhoneNumber {
  id?: string
  entityId?: string
  phoneNumber?: string
  isProposal?: boolean
  isMyProposal: boolean
}

export interface IContactBoxProps {
  showCreate?: boolean
  communityUser: MainSchema.CommunityUser
}

function ContactBox({ showCreate = true, communityUser }: IContactBoxProps) {
  const { community } = useCommunity()
  // Use the single/currently active community to load the property communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const communityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )
  const { me } = useAppContext()
  const [deleteModal, deleteActions] = useEntityModal<IProposal>()

  const s = useScopedI18n('blocks.proposal.contact')

  const [proposedContact, setProposedContact] = React.useState<string>('')
  const [isAdding, setIsAdding] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  const [deleteProposal] = useMutation<
    Pick<MainSchema.Mutation, 'deleteProposal'>,
    MainSchema.MutationDeleteProposalArgs
  >(deleteProposalMutation)

  const handleOpenDeleteModal = deleteActions.openModal
  const handleDeleteModalClose = deleteActions.closeModal

  let emails: IContactEmail[] = []
  let phoneNumbers: IContactPhoneNumber[] = []

  if ('emails' in communityUser) {
    emails = uniqBy(
      communityUser.emails?.map(record => ({
        id: record.id,
        entityId: record.entityId,
        email: record.value,
        isProposal: record.isProposal,
        isMyProposal: record.creatorId === me?.id,
      })),
      'email',
    )

    phoneNumbers = uniqBy(
      communityUser.phoneNumbers?.map(record => ({
        id: record.id,
        entityId: record.entityId,
        phoneNumber: record.value,
        isProposal: record.isProposal,
        isMyProposal: record.creatorId === me?.id,
      })),
      'phoneNumber',
    )
  }

  const { icon } = React.useMemo(
    () => ({
      icon: <IconContacts color={colors.icon.profile} height={12} width={12} />,
    }),
    [],
  )

  const handleDelete = useCallback(
    async (success: boolean) => {
      if (!success || !deleteModal.entity?.id) return

      try {
        await deleteProposal({
          variables: { id: deleteModal.entity.id },
        })

        toast.success({
          title: 'Delete proposal',
          text: `Proposal successfully deleted`,
        })
      } catch (error) {
        let message = _('error.generic')

        if (error instanceof Error) {
          message = _(`error.${error.message || 'generic'}`)
        }

        toast.error({
          title: 'Delete proposal',
          text: message,
        })
      }
    },
    [deleteModal, deleteProposal],
  )

  const { formatPhoneNumber, formalizePhoneNumber, validatePhoneNumber } =
    usePhoneNumber()

  const [createProposal] = useMutation<
    Pick<MainSchema.Mutation, 'createProposal'>,
    MainSchema.MutationCreateProposalArgs
  >(createProposalMutation)

  const isEmail = useCallback((value: string) => emailRegex.test(value), [])

  const handleChange = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target

      setProposedContact(isEmail(value) ? value : formatPhoneNumber(value))
    },
    [formatPhoneNumber, isEmail],
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target

      setProposedContact(
        isEmail(value) ? value : formalizePhoneNumber(value).toString(),
      )
    },
    [isEmail, formalizePhoneNumber],
  )

  const handleCancel = React.useCallback(async () => {
    setIsAdding(false)
  }, [])

  const handleSave = useCallback(async () => {
    if (!community) {
      return
    }

    setLoading(true)

    try {
      await createProposal({
        variables: {
          communityId: community?.id,
          // TODO: update to use communityUserId
          userId: communityUser.userId,
          kind: emailRegex.test(proposedContact)
            ? ProposalKind.Email
            : ProposalKind.Phone,
          value: proposedContact,
        },
      })

      setProposedContact('')

      // TODO: Since there is no updater for this mutation, we need to reload the profile
      EventBus.trigger(EventBus.actions.profile.reload)

      toast.success({
        title: s('messageTitle'),
        text: s('success'),
      })
    } catch (error) {
      let message = _('error.generic')

      if (error instanceof Error) {
        message = _(`error.${error.message || 'generic'}`)
      }

      toast.error({
        title: s('messageTitle'),
        text: message,
      })
    } finally {
      setLoading(false)
    }
  }, [community, createProposal, communityUser.userId, proposedContact, s])

  const handleAddClick = React.useCallback(async () => {
    setIsAdding(true)
  }, [])

  return (
    <>
      <Card>
        <Row>
          <Column>
            <SectionTitle
              icon={icon}
              showPlusButton={!!communityUserId && showCreate}
              title={s('title')}
              onPlusClicked={handleAddClick}
            />
          </Column>
          <Column fullWidth gap={1} pl={5}>
            {isAdding && (
              <Row fullWidth>
                <InlineTextboxCreate
                  disabled={
                    !(
                      validatePhoneNumber(proposedContact) ||
                      emailRegex.test(proposedContact)
                    )
                  }
                  placeholder={s('placeholder')}
                  value={proposedContact}
                  onBlur={handleBlur}
                  onCancel={handleCancel}
                  onChange={handleChange}
                  onSave={handleSave}
                />
              </Row>
            )}
            {isLoading && (
              <Row>
                <Loader />
              </Row>
            )}
            <ShowMore initialShown={3}>
              {emails.map(item => (
                <Container key={item.id}>
                  <Link href={`mailto:${item.email}`} isExternal>
                    <Text
                      color={colors.main.primary}
                      ellipsis
                      fontSize={'14px'}
                      fontWeight={400}
                      lineHeight={'16px'}
                    >
                      {item.email}
                    </Text>
                  </Link>
                  {item.isProposal && item.isMyProposal && (
                    <Button
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()

                        handleOpenDeleteModal({
                          id: item.id,
                          kind: ProposalKind.Email,
                          name: item.email,
                        })
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </Container>
              ))}

              {phoneNumbers.map(item => (
                <Container key={item.id}>
                  <ConditionalLink
                    condition={item.phoneNumber !== PRIVATE}
                    href={`tel:${item.phoneNumber}`}
                    isExternal
                  >
                    <Text
                      color={colors.main.primary}
                      ellipsis
                      fontSize={'12px'}
                      fontWeight={500}
                      lineHeight={'16px'}
                    >
                      {item.phoneNumber}
                    </Text>
                  </ConditionalLink>
                  {item.isProposal && item.isMyProposal && (
                    <Button
                      onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()

                        handleOpenDeleteModal({
                          id: item.id,
                          kind: ProposalKind.Phone,
                          name: item.phoneNumber,
                        })
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </Container>
              ))}
            </ShowMore>
          </Column>
        </Row>
      </Card>

      {deleteModal.entity && (
        <Dialog
          content={`Are you sure you want to delete your ${deleteModal.entity.kind} proposal: ${deleteModal.entity.name}?`}
          isOpen={deleteModal.isOpen}
          title="Delete proposal?"
          onClose={handleDeleteModalClose}
          onFinish={handleDelete}
        />
      )}
    </>
  )
}

export default ContactBox
