import { ApolloCache, MutationUpdaterFunction } from '@apollo/client'
import getCommunityUserQuery from 'GraphQL/Queries/CommunityUser/getCommunityUser.graphql'

import forEach from 'lodash/forEach'

import EventBus from 'Services/EventBus'

interface ICommunityUser {
  userId?: string
  communityUserId?: string
  communityId: string
}

type IGetCommunityUserUpdater = MutationUpdaterFunction<
  Pick<MainSchema.Mutation, 'addUsersToCommunities'>, // TData
  {}, // TVariables
  any, // TContext
  ApolloCache<any> // TCache
>

export function GetCommunityUserCommunityUpdater({
  communityUsers,
  communities,
}: {
  communityUsers: ICommunityUser[]
  communities: MainSchema.Community[]
}): IGetCommunityUserUpdater {
  return (cache, { data }) => {
    if (!data?.addUsersToCommunities) return

    forEach(communityUsers, communityUser => {
      const getCommunityUserData = cache.readQuery<
        Pick<MainSchema.Query, 'getCommunityUser'>,
        MainSchema.QueryGetCommunityUserArgs
      >({
        query: getCommunityUserQuery,
        variables: {
          communityUserId: communityUser.communityUserId,
          communityIds: communities.map(e => e.id),
        },
      })

      // No cache found
      if (!getCommunityUserData) {
        // failsafe call reload if available
        EventBus.trigger(EventBus.actions.profile.reload)
        return
      }

      if (getCommunityUserData?.getCommunityUser) {
        const combinedCommunities: MainSchema.Community[] = [
          ...(getCommunityUserData.getCommunityUser.communities || []),
          ...communities.filter(e => e.id === communityUser.communityId),
        ]

        const communityMap = new Map<string, MainSchema.Community>()

        for (const community of combinedCommunities) {
          if (!communityMap.has(community.id)) {
            communityMap.set(community.id, community)
          }
        }

        const mergedCommunities: MainSchema.Community[] = Array.from(
          communityMap.values(),
        )

        cache.writeQuery<
          Pick<MainSchema.Query, 'getCommunityUser'>,
          MainSchema.QueryGetCommunityUserArgs
        >({
          query: getCommunityUserQuery,
          variables: {
            communityUserId: communityUser.communityUserId,
            communityIds: communities.map(e => e.id),
          },
          data: {
            ...getCommunityUserData,
            getCommunityUser: {
              ...getCommunityUserData.getCommunityUser,
              communities: mergedCommunities,
            },
          },
        })
      }
    })
  }
}
