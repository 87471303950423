import React from 'react'
import { MultiValue } from 'react-select'

import { useApolloClient, useMutation } from '@apollo/client'
import debounce from 'awesome-debounce-promise'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import { IGraphCommunityNode } from 'Features/GraphNodes/NodeTypes'
import addUsersToCommunitiesMutation from 'Features/ProfilePanel//Mutations/addUsersToCommunities.graphql'
import userCommunitiesQuery from 'Features/ProfilePanel//Queries/userCommunities.graphql'
import { GetCommunityUserCommunityUpdater } from 'Features/ProfilePanel/Updaters/GetCommunityUserCommunityUpdater'
import { setMinSearchLength } from 'Utils/Form'
import {
  communitiesToOptions,
  ICommunityOption,
  ICommunityOptionInput,
} from 'Utils/Options'

import forEach from 'lodash/forEach'

import { Column, Loader, Row, Tag } from 'Components/UI'
import { ITagMouseEvent } from 'Components/UI/Tag/styles'

import { NODE_KIND } from 'Constants/graph'
import { DEFAULT_MIN_SEARCH_SIZE, DEFAULT_SEARCH_DEBOUNCE } from 'Constants/ids'
import { SortInputOrder } from 'Constants/mainGraphQL'
import { TAG_COLOR_KIND } from 'Constants/tags'

import { useAppContext, useCommunity } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import colors from 'Theme/_v2/colors'

import CommunityItem from './CommunityItem/CommunityItem'
import SectionTitle from './SectionTitle/SectionTitle'

import ShowMore from '../Buttons/ShowMore'
import Card from '../Card'
import { IconCommunities } from '../icons.styles'
import InlineDropdownCreate from '../InlineDropdownCreate/InlineDropdownCreate'

export interface ICommunitiesProps {
  id?: string
  name?: string
  description?: string
  photoUrl?: string
}

export interface ICommunities extends React.PropsWithChildren {
  communities?: ICommunitiesProps[]
  onRemove?: (value: string) => void
  communityUser: MainSchema.CommunityUser
}

function Communities({
  children,
  communities = [],
  onRemove,
  communityUser,
}: ICommunities) {
  const { community } = useCommunity()
  const { me } = useAppContext()
  const client = useApolloClient()
  const s = useScopedI18n('community.communityUserCommunities')
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnCommunities } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()

  const [isAdding, setIsAdding] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)

  const { icon } = React.useMemo(
    () => ({
      icon: (
        <IconCommunities color={colors.icon.profile} height={12} width={12} />
      ),
      title: s('name'),
      tagKind: TAG_COLOR_KIND.COMMUNITY,
    }),
    [s],
  )

  const [selectedCommunities, setSelectedCommunities] = React.useState<
    MainSchema.Community[]
  >([])

  // TODO: update mutation to use communityUserId
  const [addUsersToCommunities] = useMutation<
    Pick<MainSchema.Mutation, 'addUsersToCommunities'>,
    MainSchema.MutationAddUsersToCommunitiesArgs
  >(addUsersToCommunitiesMutation)

  const loadCommunitiesOptions = React.useCallback(
    async (
      inputValue: string,
      callback: (options: ICommunityOptionInput[]) => void,
    ) => {
      try {
        const result = await client.query({
          query: userCommunitiesQuery,
          variables: {
            search: inputValue,
            limit: 25,
            page: 0,
            sort: [
              {
                column: 'name',
                order: SortInputOrder.Asc,
              },
            ],
          },
        })

        const options = result?.data?.userCommunities?.rows.map(
          (community: ICommunityOption) => ({
            ...community,
          }),
        )

        callback(communitiesToOptions(options))
      } catch (error: any) {
        toast.error({
          title: s('errorTitle'),
          text: error.message,
        })
      }
    },
    [client, s],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = React.useCallback(
    setMinSearchLength(
      debounce(loadCommunitiesOptions, DEFAULT_SEARCH_DEBOUNCE),
      DEFAULT_MIN_SEARCH_SIZE,
    ),
    [loadCommunitiesOptions],
  )

  const renderMultiValue = React.useCallback(
    (selectProps: any) => (
      <Tag
        colorKind={NODE_KIND.community}
        passClickThrough
        small
        text={selectProps?.children?.props?.text ?? selectProps?.children}
      />
    ),
    [],
  )

  const handleIsValidNewOption = React.useCallback((inputValue: string) => {
    return inputValue?.length >= 3
  }, [])

  const handleOnChange = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (newValue: MultiValue<ICommunityOptionInput>) => {
      const selectedCommunities = newValue.map(community => ({ ...community }))
      // Filter newly added communities that need to be created
      const communitiesToAdd = selectedCommunities
        .filter(options => options.id)
        .map(option => ({
          id: option?.id,
          name: option?.name,
        })) as MainSchema.Community[]
      setSelectedCommunities(communitiesToAdd)
    },
    [],
  )

  const handleItemClick = (e: ITagMouseEvent) => {
    if (isFeatureEnabled('regraph')) {
      handleSpawnCommunities([e.entity as IGraphCommunityNode])
      focusNodes([e.entity?.id])
    } else {
      EventBus.trigger(EventBus.actions.search.community, {
        id: e.entity.id,
        type: NODE_KIND.community,
        photoUrl: e.entity.photoUrl,
        label: e.entity.name,
        value: e.entity.name,
      })
      EventBus.trigger(EventBus.actions.graph.focusNode, e.entity.id)
    }
  }

  const handleOnAddClick = React.useCallback(async () => {
    setIsAdding(true)
  }, [])

  const handleCancel = React.useCallback(async () => {
    setIsAdding(false)
  }, [])

  const handleSave = React.useCallback(async () => {
    setIsAdding(false)
    setLoading(true)
    if (community && communityUser?.userId && selectedCommunities.length) {
      await addUsersToCommunities({
        variables: {
          userIds: [communityUser.userId],
          communityIds: selectedCommunities.map(community => community.id),
          sourceCommunityId: community.id,
        },
        update: GetCommunityUserCommunityUpdater({
          communityUsers: [
            {
              userId: communityUser.userId,
              communityId: community.id,
            },
          ],
          communities: me?.communities || [],
        }),
      })
    }
    forEach(selectedCommunities, community => {
      EventBus.trigger(EventBus.actions.graph.addCommunityEdge, {
        fromId: communityUser?.communityUserId,
        toId: community.id,
        kind: NODE_KIND.community,
      })
    })
    setLoading(false)
  }, [
    community,
    communityUser.userId,
    communityUser.communityUserId,
    selectedCommunities,
    addUsersToCommunities,
    me?.communities,
  ])

  return (
    <Card>
      <Row>
        <Column>
          <SectionTitle
            icon={icon}
            showPlusButton={!isAdding}
            title={s('name')}
            onPlusClicked={handleOnAddClick}
          />
        </Column>
        <Column fullWidth pl={5}>
          {isAdding && (
            <Row fullWidth>
              <InlineDropdownCreate
                debouncedLoadOptions={debouncedLoadOptions}
                isValidNewOption={handleIsValidNewOption}
                placeholder={s('placeholder')}
                renderMultiValue={renderMultiValue}
                onCancel={handleCancel}
                onChange={handleOnChange}
                onSave={handleSave}
              />
            </Row>
          )}
          {isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
          {communities.length > 0 && (
            <ShowMore flexDirection={'column'} gap={2} initialShown={6}>
              {communities.map(community => (
                <Row fullWidth key={community?.id}>
                  <CommunityItem
                    colorKind={TAG_COLOR_KIND.COMMUNITY}
                    entity={community}
                    key={community?.id}
                    removable={!!onRemove}
                    small
                    text={community.name}
                    onClick={handleItemClick}
                  />
                </Row>
              ))}
            </ShowMore>
          )}
          {children}
        </Column>
      </Row>
    </Card>
  )
}

export default Communities
