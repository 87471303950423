import React from 'react'

import { flexRender } from '@tanstack/react-table'

import { Button, Loader } from 'Components/UI'

import {
  Sort,
  Table,
  TableData,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from './styles'

interface Props {
  table: any
  handleRowClick: (rowId: number) => void
  handleNameClick: (communityUserId: string) => void
  currentPage: number
  totalPages: number
  isLoading: boolean
  handleLoadMore: (() => void) | null
  filterText: string
}

const PeopleTableView: React.FC<Props> = ({
  table,
  handleRowClick,
  handleNameClick,
  currentPage,
  totalPages,
  isLoading,
  handleLoadMore,
  filterText,
}) => {
  return (
    <>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => (
                <TableHeader
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                  {header.column.getCanSort() && <Sort />}
                </TableHeader>
              ))}
            </TableHeaderRow>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: any) => (
            <TableRow key={row.id} onClick={() => handleRowClick(row.index)}>
              {row.getVisibleCells().map((cell: any) => {
                const cellOnClick =
                  cell.column.id === 'name' && handleNameClick
                    ? (event: React.MouseEvent<HTMLTableCellElement>) => {
                        event.stopPropagation()
                        handleNameClick(row.original.communityUserId)
                      }
                    : undefined

                return (
                  <TableData key={cell.id} onClick={cellOnClick}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableData>
                )
              })}
            </TableRow>
          ))}
        </tbody>
      </Table>
      {currentPage < totalPages &&
        handleLoadMore &&
        !filterText &&
        (isLoading ? (
          <Loader />
        ) : (
          <Button style={{ margin: '0 auto' }} onClick={handleLoadMore}>
            Load More
          </Button>
        ))}
    </>
  )
}

export default PeopleTableView
