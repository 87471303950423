import { Chart } from 'regraph'

import { AnalyzerFunction } from './Helpers/AnalyzersFunction'

export const COMBO_PROPERTIES = ['cluster', 'clusterType', 'clusterContainer']

export enum LayoutType {
  Organic = 'organic',
  Sequential = 'sequential',
  Structural = 'structural',
  Radial = 'radial',
  Lens = 'lens',
  Standard = 'standard',
  Tweak = 'tweak',
}

export const DEFAULT_LAYOUT: Chart.LayoutOptions = {
  name: LayoutType.Organic,
  top: undefined,
  curvedLinks: false,
  tightness: 3,
}

export const DEFAULT_OPTIONS: Chart.Options = {
  hoverDelay: 75,
  navigation: false,
  overview: false,
  fit: 'none',
  iconFontFamily: 'Inter',
  labels: {
    fontFamily: 'Inter',
  },
  selection: false,
  dragPan: true,
}

export const ANALYZERS_NAME: Record<string, AnalyzerFunction> = {
  between: 'betweenness',
  close: 'closeness',
  degree: 'degrees',
  central: 'eigenCentrality',
  rank: 'pageRank',
}

export const NODE_KIND = {
  user: 'user',
  skill: 'skill',
  role: 'role',
  project: 'project',
  event: 'event',
  group: 'group',
  custom: 'custom',
  organization: 'organization',
  knowledge: 'knowledge',
  community: 'community',
  areaOfExperience: 'areaOfExperience',
} as const
export type NodeKindKeys = keyof typeof NODE_KIND
export type NodeKind = (typeof NODE_KIND)[NodeKindKeys]

export enum ItemType {
  User = 'user',
  Skill = 'skill',
  Tag = 'tag',
  Organization = 'organization',
  Knowledge = 'knowledge',
  Community = 'community',
  Edge = 'edge',
  RelationshipStrengthEdge = 'relationshipStrengthEdge',
  AreaOfExperience = 'areaOfExperience',
  // Obsolete
  SkillTag = 'skillTag',
}

export enum PanelType {
  AddSkillTag = 'AddSkillTag',
  Note = 'Note',
  Organization = 'Organization',
  CommunityUser = 'CommunityUser',
}

export const ACTION_KIND = {
  addCommunityUser: 'addCommunityUser',
  meetCommunityUser: 'meetCommunityUser',
  skill: 'skill',
  role: 'role',
  project: 'project',
  event: 'event',
  group: 'group',
  custom: 'custom',
  organization: 'organization',
}
export type ActionKindKeys = keyof typeof ACTION_KIND
export type ActionKind = (typeof ACTION_KIND)[ActionKindKeys]

export type SkillTagKind = Exclude<NodeKind, 'user'>

export const COMBO_NAME: Record<SkillTagKind, string> = {
  [NODE_KIND.event]: 'Events',
  [NODE_KIND.skill]: 'Skills',
  [NODE_KIND.project]: 'Projects',
  [NODE_KIND.group]: 'Groups',
  [NODE_KIND.role]: 'Roles',
  [NODE_KIND.custom]: 'Custom Tags',
  [NODE_KIND.organization]: 'Organizations',
  [NODE_KIND.knowledge]: 'Knowledge',
  [NODE_KIND.community]: 'Communities',
  [NODE_KIND.areaOfExperience]: 'Areas of Experience',
}

export const NODE_NAME: Record<SkillTagKind, string> = {
  [NODE_KIND.event]: 'Event',
  [NODE_KIND.skill]: 'Skill',
  [NODE_KIND.project]: 'Project',
  [NODE_KIND.group]: 'Group',
  [NODE_KIND.role]: 'Role',
  [NODE_KIND.custom]: 'Custom',
  [NODE_KIND.organization]: 'Organization',
  [NODE_KIND.knowledge]: 'Knowledge',
  [NODE_KIND.community]: 'Community',
  [NODE_KIND.areaOfExperience]: 'Area of Experience',
}

export const QUICK_ACTION_KIND = {
  PATH_TO_USER: 'pathToUser',
  ADD_TO_COMMUNITY: 'addToCommunity',
  CREATE_NOTE: 'createNote',
  VIEW_PROFILE: 'viewProfile',
  INTRODUCE_TO: 'introduceTo',
  ADD_SKILLS_TAGS: 'addSkillsTags',
  QUICK_CONNECTOR: 'quickConnector',
  EDIT: 'edit',
  ARCHIVE: 'archive',
  HIDE: 'hide',
  VIEW_SELECTED_ITEMS: 'viewSelectedItems',
  PIN_TO_GRAPH: 'pinToGraph',
  REMOVE_PIN: 'removePin',
} as const
export type QuickActionKindKeys = keyof typeof QUICK_ACTION_KIND
export type QuickActionKind = (typeof QUICK_ACTION_KIND)[QuickActionKindKeys]
export const GRAPH_ANIMATION_TIME = 500

export const QUICK_CONNECTOR_PATH = '/img/quick-connector.svg'
export const ASK_GLYPH_PATH = '/img/ask-glyph.svg'

export const DEFAULT_DATA = {
  people: {},
  tags: {},
  skills: {},
  organizations: {},
  communities: {},
  peopleEdges: {},
  tagEdges: {},
  skillEdges: {},
  workHistoryEdges: {},
  educationHistoryEdges: {},
  communityEdges: {},
  allEdges: {},
}

export const CONTEXT_NETWORK_FIELD = {
  /* *** NETWORK *** */
  SHOW_NETWORK: 'showNetwork',
  FIRST_DEGREE: 'firstDegree',
  SECOND_DEGREE: 'secondDegree',
  THIRD_DEGREE: 'thirdDegree',
  ALL_TAGS: 'allTags',
  ALL_USERS: 'allUsers',
  /* *** LAYOUTS *** */
  LAYOUTS: 'layouts',
  ORGANIC_LAYOUT: 'organicLayout',
  SEQUENTIAL_LAYOUT: 'sequentialLayout',
  STRUCTURAL_LAYOUT: 'structuralLayout',
  RADIAL_LAYOUT: 'radialLayout',
  LENS_LAYOUT: 'lensLayout',
  /* *** RELATIONSHIP STRENGTH *** */
  RELATIONSHIP_STRENGTH: 'relationshipStrength',
  SHOW_STRONG: 'showStrong',
  SHOW_MODERATE: 'showModerate',
  SHOW_WEAK: 'showWeak',
  SHOW_NONE: 'showNone',
  /* *** RELATIONSHIP STRENGTH *** */
  NETWORK_ANALYZER: 'networkAnalyzer',
  NO_ANALYZER: 'noAnalyzer',
  BETWEENESS: 'betweenness',
  CLOSENESS: 'closeness',
  DEGREES: 'degrees',
  EIGEN_CENTRALITY: 'eigenCentrality',
  PAGE_RANK: 'pageRank',
  /* *** SHOW EDGES *** */
  SHOW_PEOPLE_CONNECTIONS_EDGES: 'showPeopleConnectionsEdges',
  SHOW_SKILL_CONNECTIONS_EDGES: 'showSkillConnectionsEdges',
  SHOW_TAG_CONNECTIONS_EDGES: 'showTagConnectionsEdges',
  SHOW_WORK_HISTORY_CONNECTIONS_EDGES: 'showWorkHistoryConnectionsEdges',
  SHOW_EDUCATION_HISTORY_CONNECTIONS_EDGES:
    'showEducationHistoryConnectionEdges',
  SHOW_COMMUNITY_CONNECTIONS_EDGES: 'showCommunityConnectionsEdges',
  /* *** CLUSTERING *** */
  CLUSTER: 'cluster',
}

export const CONTEXT_RESET_FIELD = {
  /* *** RESET *** */
  RELAYOUT_GRAPH: 'relayoutGraph',
  RESET_GRAPH: 'resetGraph',
}

export const CONTEXT_GRAPH_FIELD = {
  /* *** GRAPH_CONTEXT *** */
  SELECTED_ITEMS: 'selectedItems',
  REMOVE_SELECTED: 'removeSelected',
}
