import React, { useEffect, useMemo } from 'react'

import { IconChevronLeft } from '@tabler/icons-react'
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { useGraphLoaders } from 'Features/GraphNodes/useGraphLoader'
import NoteForm from 'Features/Notes/Components/NoteForm'
import useNoteForm from 'Features/Notes/Components/NoteForm/useNoteForm'
import NoteTags from 'Features/Notes/Components/NoteTags'

import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import keyBy from 'lodash/keyBy'

import { Button, Column, Row } from 'Components/UI'
import { Link } from 'Components/UI/_v2'

import { useCommunity } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './Note.styles'

export interface INoteProps {
  communityUserId: string
  noteId?: string
  onBack: () => void
}

const Note = (props: INoteProps) => {
  const t = useScopedI18n('components.blocks.userProfile.tabs.notes.note')
  const { community } = useCommunity()
  const { loadPeople, loadPeopleData } = useGraphLoaders()

  const {
    communityUser,
    isLoading,
    formStatus,
    formValues,
    canToggleIsPublic,
    canSubmit,
    handleToggleIsFavorite,
    handleToggleIsPublic,
    handleAddMention,
    handleChangeContent,
  } = useNoteForm({
    communityUserId: props.communityUserId,
    noteId: props.noteId,
  })

  const { connectedCommunityUserIds, userTags, userSkills } = useMemo(() => {
    return {
      connectedCommunityUserIds:
        communityUser?.connections?.map(
          (connection: MainSchema.CommunityUserConnection) =>
            connection?.toCommunityUserId,
        ) || [],
      userTags: communityUser?.communityUserTags?.map(
        (tag: MainSchema.CommunityUserTag) => tag.tag,
      ),
      userSkills: communityUser?.communityUserSkills?.map(
        (skill: MainSchema.CommunityUserSkill) => skill.skill,
      ),
    }
  }, [communityUser])

  const relatedCommunityUserIds = useMemo(
    () =>
      filter(
        [
          ...connectedCommunityUserIds,
          ...formValues.mentionedItems.toMeetCommunityUserIds,
          ...formValues.mentionedItems.toMentionedCommunityUserIds,
          ...formValues.mentionedItems.toAppendCommunityUserIds,
        ],
        item => item !== undefined,
      ),
    [
      connectedCommunityUserIds,
      formValues.mentionedItems.toMeetCommunityUserIds,
      formValues.mentionedItems.toMentionedCommunityUserIds,
      formValues.mentionedItems.toAppendCommunityUserIds,
    ],
  )

  const communityUsersById = useMemo(
    () => keyBy(loadPeopleData?.nodes, 'communityUserId'),
    [loadPeopleData?.nodes],
  )

  // TODO: This is entirely outside of the graph, it should not be using or loading IGraphPersonNode here.
  // If I recall correctly this was a quick fix to keep notes functional.
  const connectedCommunityUsers = useMemo(() => {
    const innerArray: IGraphPersonNode[] = []

    forEach(connectedCommunityUserIds, communityUserId => {
      if (communityUsersById[communityUserId]) {
        innerArray.push(communityUsersById[communityUserId])
      }
    })
    return innerArray
  }, [connectedCommunityUserIds, communityUsersById])

  useEffect(() => {
    if (community?.id && relatedCommunityUserIds.length > 0) {
      // TODO: This needs to be extracted somewhere else, graph loader should never leave the graph context.
      loadPeople({
        variables: {
          // TODO: update to use communityUserId
          communityIds: [community.id],
          communityUserIds: relatedCommunityUserIds,
          limit: relatedCommunityUserIds.length,
        },
      })
    }
  }, [community?.id, relatedCommunityUserIds, loadPeople])

  if (!communityUser || isLoading) {
    return <></>
  }

  return (
    <>
      <Styled.NoteEditorScrollContainer>
        <Column grow>
          <Link
            alignItems="center"
            display="flex"
            flexDirection="row"
            fontSize="12px"
            fontWeight={600}
            gap={1}
            variant="secondary"
            onClick={props.onBack}
          >
            <IconChevronLeft size={16} />
            {t('back')}
          </Link>

          <Row center pb={3} pt={4} spaceBetween>
            <NoteForm.IsPublicField
              isDisabled={!canToggleIsPublic}
              value={formValues.isPublic}
              onChange={handleToggleIsPublic}
            />

            <NoteForm.IsFavoriteField
              value={formValues.isFavorite}
              onChange={handleToggleIsFavorite}
            />
          </Row>

          <Column>
            <NoteForm.ContentField
              communityUserId={props.communityUserId}
              existingMentions={formValues.existingMentions}
              value={formValues.content}
              onAddMention={handleAddMention}
              onChangeContent={handleChangeContent}
            />
          </Column>

          <Row center pb={6} pt={3} spaceBetween>
            <NoteForm.StatusMessage status={formStatus} />

            <Button
              disabled={!canSubmit}
              minWidth="80px"
              onClick={props.onBack}
            >
              {t('submit')}
            </Button>
          </Row>
        </Column>
      </Styled.NoteEditorScrollContainer>

      <Styled.ScrollContainer>
        <NoteTags.CommunityUserTags
          appendCommunityUsers={formValues.mentionedItems.toAddCommunityUsers}
          connectedCommunityUsers={connectedCommunityUsers}
          meetCommunityUserIds={
            formValues.mentionedItems.toMeetCommunityUserIds
          }
          meetCommunityUsers={formValues.mentionedItems.toMeetCommunityUsers}
          mentionedCommunityUserIds={
            formValues.mentionedItems.toMentionedCommunityUserIds
          }
          targetCommunityUser={communityUser}
        />

        <NoteTags.SkillTags
          appendSkills={formValues.mentionedItems.toAppendSkills}
          communityUserId={communityUser.id}
          userSkills={
            userSkills?.map(userSkill => ({
              id: userSkill!.id,
              name: userSkill!.name,
            })) ?? []
          }
        />

        <NoteTags.Tags
          communityUserId={communityUser.id}
          customTags={formValues.mentionedItems.customTags}
          events={formValues.mentionedItems.events}
          existingTags={
            userTags?.map(userTag => ({
              id: userTag!.id,
              name: userTag!.name,
              kind: userTag!.kind,
            })) ?? []
          }
          groups={formValues.mentionedItems.groups}
          projects={formValues.mentionedItems.projects}
          roles={formValues.mentionedItems.roles}
        />
      </Styled.ScrollContainer>
    </>
  )
}

export default Note
