/* eslint-disable no-param-reassign */
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { GraphItem, Index, Link } from 'regraph'

import { createEdge } from 'Components/Blocks/Graph/createEdge'
import { IItemData } from 'Components/Blocks/Graph/types'

import { NODE_KIND } from 'Constants/graph'

import createExploreNode from './createExploreNode'
import createExploreQueryNode from './createExploreQueryNode'
import { IExploreNode, IExploreQueryNode } from './ExploreQuery.types'

export default function processExploreQueryNodes(
  explore: IExploreNode[],
  exploreQuery: IExploreQueryNode[],
  users: IGraphPersonNode[],
  selectedIds: string[],
  graphNodes: Index<GraphItem<IItemData<any, any>>>,
  graphEdges: Index<Link<IItemData>>,
) {
  exploreQuery.forEach(exploreQueryData => {
    const exploreQueryNode = createExploreQueryNode({
      exploreQueryNode: exploreQueryData,
      isSelected: selectedIds.includes(exploreQueryData.id),
    })

    graphNodes[exploreQueryData.id] = exploreQueryNode

    explore.forEach(exploreNodeData => {
      const exploreNode = createExploreNode({
        exploreNode: exploreNodeData,
        isSelected: selectedIds.includes(exploreNodeData.id),
      })

      const edge = createEdge({
        edgeData: {
          fromId: exploreNodeData.id,
          toId: exploreQueryData.id,
          kind: NODE_KIND.explore,
        },
      })

      graphNodes[exploreNodeData.id] = exploreNode
      graphEdges[edge.data!.id] = edge
    })
  })

  if (explore.length > 0 && users.length > 0) {
    exploreQuery.forEach(exploreQueryData => {
      users.forEach(user => {
        if (user && user.userId) {
          const edge = createEdge({
            // TODO: update to use communityUserId
            edgeData: {
              fromId: exploreQueryData.id,
              toId: user.userId!,
              kind: NODE_KIND.explore,
            },
          })

          graphEdges[edge.data!.id] = edge
        }
      })
    })
  }
}
