import React from 'react'

import {
  Column,
  Divider,
  ImageInputField,
  InputField,
  Row,
} from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import _ from 'Services/I18n'

import { FIELD } from '../fields'

function GeneralTab() {
  return (
    <Column>
      <ImageInputField
        name={FIELD.PHOTO_URL}
        uploadType={UPLOAD_TYPES.profilePhoto}
      />

      <Column fullWidth gap={4} mt={4}>
        <Row fullWidth gap={4}>
          <InputField
            label={_('auth.shared.firstName')}
            name={FIELD.FIRST_NAME}
            placeholder="First name"
            required
            width={1}
          />
          <InputField
            label={_('auth.shared.lastName')}
            name={FIELD.LAST_NAME}
            placeholder="Last name"
            required
            width={1}
          />
        </Row>

        <Row gap={4}>
          <InputField
            label={_('auth.shared.email')}
            name={FIELD.EMAIL}
            placeholder="Email"
            required
            width={1}
          />

          <InputField
            label={_('auth.shared.phone')}
            name={FIELD.PHONE_NUMBER}
            phoneNumberFormat
            placeholder="Phone number"
            width={1}
          />
        </Row>
      </Column>

      <Divider my={5} />

      <Column fullWidth gap={3}>
        <InputField
          label={_('auth.shared.linkedIn')}
          name={FIELD.LINKED_IN}
          placeholder="LinkedIn"
        />

        <InputField
          label={_('auth.shared.job')}
          name={FIELD.JOB}
          placeholder="Job"
        />

        <InputField
          label={_('auth.shared.organization')}
          name={FIELD.ORGANIZATION}
          placeholder="Company"
        />
      </Column>
    </Column>
  )
}

export default GeneralTab
