import React, { useMemo } from 'react'

import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import useRegraphHandlers from 'Features/Graph/useRegraphHandlers'
import useRegraphLoaders from 'Features/Graph/useRegraphLoaders'
import { IGraphOrganizationNode } from 'Features/GraphNodes/NodeTypes'
import SectionTitle from 'Features/ProfilePanel/Blocks/Overview/SectionTitle/SectionTitle'

import CreateEducationHistoryModal from 'Components/Blocks/Modals/CreateEducationHistoryModal/CreateEducationHistoryModal'
import DeleteEducationHistoryModal from 'Components/Blocks/Modals/DeleteEducationHistoryModal/DeleteEducationHistoryModal'
import UpdateEducationHistoryModal from 'Components/Blocks/Modals/UpdateEducationHistoryModal/UpdateEducationHistoryModal'
import { Column, Row } from 'Components/UI'

import { SEARCH_TYPES } from 'Constants/ids'

import { useCommunity, useEntityModal } from 'Hooks'

import EventBus from 'Services/EventBus'
import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import EducationHistory from './EducationHistoryItem/EducationHistory'

import ShowMore from '../Buttons/ShowMore'
import Card from '../Card'
import { IconEducationHistories } from '../icons.styles'

export interface EducationHistoryBoxProps {
  communityUser: MainSchema.CommunityUser
}

export default function EducationHistories({
  communityUser,
}: EducationHistoryBoxProps) {
  const { community } = useCommunity()
  const { isFeatureEnabled } = useFeatureFlag(['regraph'])
  const { handleSpawnOrganization } = useRegraphLoaders()
  const { focusNodes } = useRegraphHandlers()
  // Use the single/currently active community to load the propert communityUserId
  // IMPORTANT: When UI allows selecting more than one community, things will behave badly in the current UI implementation
  const activeCommunityUserId = useMemo(
    () =>
      communityUser?.communityUsers?.find(e => e.communityId === community?.id)
        ?.communityUserId || null,
    [communityUser, community],
  )

  const t = useScopedI18n(
    'components.blocks.userProfile.blocks.educationHistoryBox',
  )

  const [deleteModal, deleteModalActions] =
    useEntityModal<MainSchema.CommunityUserEducationHistory>()
  const [updateModal, updateModalActions] =
    useEntityModal<MainSchema.CommunityUserEducationHistory>()
  const [createModal, createModalActions] =
    useEntityModal<MainSchema.CommunityUser>()

  const handleOpenDeleteModal = deleteModalActions.openModal
  const handleDeleteModalClose = deleteModalActions.closeModal

  const handleOpenUpdateModal = updateModalActions.openModal
  const handleUpdateModalClose = updateModalActions.closeModal

  const handleOpenCreateModal = createModalActions.openModal
  const handleCreateModalClose = createModalActions.closeModal

  const handleView = (
    educationHistory: MainSchema.CommunityUserEducationHistory,
  ) => {
    if (!educationHistory.organization) {
      return
    }

    if (isFeatureEnabled('regraph')) {
      handleSpawnOrganization([
        educationHistory.organization as IGraphOrganizationNode,
      ])
      focusNodes([educationHistory.organization.id])
    } else {
      // TODO: refactor events so this is clear about what it's doing
      EventBus.trigger(EventBus.actions.search.community, {
        id: educationHistory.organization.id,
        type: SEARCH_TYPES.organization,
        label: educationHistory.organization.name,
        value: educationHistory.organization,
      })
      EventBus.trigger(
        EventBus.actions.graph.focusNode,
        educationHistory.organization.id,
      )
    }
  }

  return (
    <>
      <Card>
        <Row>
          <Column>
            <SectionTitle
              icon={<IconEducationHistories color={colors.icon.profile} />}
              showPlusButton
              title={t('title')}
              onPlusClicked={
                activeCommunityUserId
                  ? () => handleOpenCreateModal(communityUser)
                  : undefined
              }
            />
          </Column>
          <Column gap={1} pl={5}>
            <Column gap={2}>
              <ShowMore initialShown={2}>
                {communityUser.communityUserEducationHistory
                  // Temporary filter to scope the education history to the active community
                  ?.filter(
                    educationHistory =>
                      educationHistory.communityUserId ===
                      activeCommunityUserId,
                  )
                  ?.map(educationHistory => (
                    <EducationHistory
                      educationHistory={
                        educationHistory as MainSchema.CommunityUserEducationHistory
                      }
                      key={educationHistory.id}
                      onDelete={() =>
                        handleOpenDeleteModal(
                          educationHistory as MainSchema.CommunityUserEducationHistory,
                        )
                      }
                      onEdit={() =>
                        handleOpenUpdateModal(
                          educationHistory as MainSchema.CommunityUserEducationHistory,
                        )
                      }
                      onView={handleView}
                    />
                  ))}
              </ShowMore>
            </Column>
          </Column>
        </Row>
      </Card>

      {deleteModal.entity && deleteModal.isOpen && (
        <DeleteEducationHistoryModal
          educationHistory={deleteModal.entity}
          onClose={handleDeleteModalClose}
        />
      )}

      {updateModal.entity && updateModal.isOpen && (
        <UpdateEducationHistoryModal
          educationHistory={updateModal.entity}
          onClose={handleUpdateModalClose}
        />
      )}

      {createModal.entity && createModal.isOpen && (
        <CreateEducationHistoryModal
          communityUser={createModal.entity}
          onClose={handleCreateModalClose}
        />
      )}
    </>
  )
}
