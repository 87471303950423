import React from 'react'

import { QUICK_ACTION_KIND, QuickActionKind } from 'Features/Graph/constants'

import * as Styled from './styles'

export interface IQuickActionKind {
  kind: QuickActionKind
  label: string
  icon: React.ReactNode
}

export const QUICK_ACTION_KINDS_MAP: Record<string, IQuickActionKind> = {
  [QUICK_ACTION_KIND.VIEW_PROFILE]: {
    kind: QUICK_ACTION_KIND.VIEW_PROFILE,
    label: 'tips.viewProfile',
    icon: <Styled.Profile />,
  },
  [QUICK_ACTION_KIND.CREATE_NOTE]: {
    kind: QUICK_ACTION_KIND.CREATE_NOTE,
    label: 'tips.notesCreate',
    icon: <Styled.AddNoteIcon />,
  },
  [QUICK_ACTION_KIND.ADD_SKILLS_TAGS]: {
    kind: QUICK_ACTION_KIND.ADD_SKILLS_TAGS,
    label: 'tips.addSkillsTags',
    icon: <Styled.AddTagIcon />,
  },
  [QUICK_ACTION_KIND.PATH_TO_USER]: {
    kind: QUICK_ACTION_KIND.PATH_TO_USER,
    label: 'tips.pathToUser',
    icon: <Styled.RevealPathToConnectIcon />,
  },
  [QUICK_ACTION_KIND.INTRODUCE_TO]: {
    kind: QUICK_ACTION_KIND.INTRODUCE_TO,
    label: 'tips.introduceTo',
    icon: <Styled.IntroduceIcon />,
  },
  [QUICK_ACTION_KIND.ADD_TO_COMMUNITY]: {
    kind: QUICK_ACTION_KIND.ADD_TO_COMMUNITY,
    label: 'tips.userAddCommunity',
    icon: <Styled.AddToCommunityIcon />,
  },
  [QUICK_ACTION_KIND.EDIT]: {
    kind: QUICK_ACTION_KIND.EDIT,
    label: 'tips.edit',
    icon: <Styled.EditIcon />,
  },
  [QUICK_ACTION_KIND.ARCHIVE]: {
    kind: QUICK_ACTION_KIND.ARCHIVE,
    label: 'tips.archive',
    icon: <Styled.ArchiveIcon />,
  },
  [QUICK_ACTION_KIND.HIDE]: {
    kind: QUICK_ACTION_KIND.HIDE,
    label: 'tips.hide',
    icon: <Styled.HideIcon />,
  },
  [QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS]: {
    kind: QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS,
    label: 'tips.viewSelectedItems',
    icon: <Styled.ViewSelectedItemsIcon />,
  },
}

export const GRAPH_QUICK_ACTION_KINDS: IQuickActionKind[] = [
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.VIEW_PROFILE],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.CREATE_NOTE],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ADD_SKILLS_TAGS],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.PATH_TO_USER],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.INTRODUCE_TO],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ADD_TO_COMMUNITY],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ARCHIVE],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.HIDE],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.VIEW_SELECTED_ITEMS],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.EDIT],
]

export const PROFILE_QUICK_ACTION_KINDS: IQuickActionKind[] = [
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.EDIT],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ADD_SKILLS_TAGS],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.PATH_TO_USER],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.INTRODUCE_TO],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ADD_TO_COMMUNITY],
  QUICK_ACTION_KINDS_MAP[QUICK_ACTION_KIND.ARCHIVE],
]
