import React from 'react'

import { format, intervalToDuration, parseISO } from 'date-fns'
import { getOrganizationLogoUrl } from 'Utils/Organization'
import { formatEducation } from 'Utils/User'

import { Box, Column, Row } from 'Components/UI'
import { Text } from 'Components/UI/_v2'

import { useScopedI18n } from 'Services/I18n'

import colors from 'Theme/_v2/colors'

import * as Styled from './EducationHistory.styles'

import Dropdown, { DropdownOption } from '../../DotsDropdown/DotsDropdown'
import { IconEducationHistoryPlaceholder } from '../../icons.styles'

export interface EducationHistoryProps {
  educationHistory: MainSchema.CommunityUserEducationHistory
  onView?: (educationHistory: MainSchema.CommunityUserEducationHistory) => void
  onEdit?: (educationHistory: MainSchema.CommunityUserEducationHistory) => void
  onDelete?: (
    educationHistory: MainSchema.CommunityUserEducationHistory,
  ) => void
}

export default function EducationHistory({
  educationHistory,
  onView,
  onEdit,
  onDelete,
}: EducationHistoryProps) {
  const t = useScopedI18n(
    'components.blocks.userProfile.blocks.educationHistory',
  )

  const startDate = educationHistory.startDate
    ? parseISO(educationHistory.startDate)
    : null
  const endDate = educationHistory.endDate
    ? parseISO(educationHistory.endDate)
    : null
  const duration = startDate
    ? intervalToDuration({
        start: startDate,
        end: endDate ?? new Date(),
      })
    : null

  const dropdownOptions: DropdownOption[] = []

  if (!educationHistory.isEnriched) {
    dropdownOptions.push({
      key: 'edit',
      label: t('actions.edit'),
      onClick: () => onEdit?.(educationHistory),
    })
  }

  dropdownOptions.push({
    key: 'delete',
    label: t('actions.delete'),
    onClick: () => onDelete?.(educationHistory),
  })

  const logoUrl = getOrganizationLogoUrl(educationHistory.organization?.logos)

  return (
    <Styled.EducationHistory onClick={() => onView?.(educationHistory)}>
      <Box
        backgroundColor={'#F0F0F0'}
        borderRadius={'100%'}
        display={'flex'}
        height={'12px'}
        justifyContent={'center'}
        marginTop={'3px'}
        overflow={'hidden'}
        width={'12px'}
      >
        {educationHistory.organization && logoUrl ? (
          <Styled.EducationHistoryImage
            alt={t('logo.alt', {
              organizationName: educationHistory.organization.name,
            })}
            src={logoUrl}
          />
        ) : (
          <IconEducationHistoryPlaceholder color={colors.icon.profile} />
        )}
      </Box>

      <Column flex={1}>
        <Text
          color={colors.text.primary}
          fontSize={'14px'}
          fontWeight={500}
          lineHeight={'16px'}
          whiteSpace={'pre-line'}
        >
          {formatEducation(educationHistory)}
        </Text>

        <Row alignItems={'center'}>
          <Text
            color={colors.text.secondary}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            {t('duration', {
              count: duration?.years ?? 0,
            })}
          </Text>

          <Text
            color={'#d9d9d9'}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            &nbsp;&bull;&nbsp;
          </Text>

          <Text
            color={colors.text.secondary}
            fontSize={'10px'}
            fontWeight={400}
            lineHeight={'16px'}
          >
            {startDate ? format(startDate, 'MMM yyyy') : t('startDate.empty')}
            &nbsp;-&nbsp;
            {endDate ? format(endDate, 'MMM yyyy') : t('endDate.empty')}
          </Text>
        </Row>
      </Column>

      <Dropdown options={dropdownOptions} />
    </Styled.EducationHistory>
  )
}
