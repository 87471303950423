import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useApolloClient, useQuery } from '@apollo/client'
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { format } from 'date-fns'
import NameCell from 'Features/PeopleTable/NameCell'
import PeopleEmptyState from 'Features/PeopleTable/PeopleEmptyState'
import PeopleTableControls from 'Features/PeopleTable/PeopleTableControls'
import PeopleTableView from 'Features/PeopleTable/PeopleTableView'
import ProfilePanel from 'Features/ProfilePanel/ProfilePanel'
import userCommunitiesQuery from 'GraphQL/Queries/Community/userCommunities.graphql'
import listCommunityUsersQuery from 'GraphQL/Queries/CommunityUser/listCommunityUsers.full.graphql'

import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { SelectionCell, SelectionHeader } from './SelectionHeader'
import { Heading, ProfilePanelWrapper, Wrapper } from './styles'
import { useTaskUsers } from './useTaskUsers'

export type PersonRow = {
  communities?: string
  createdAt?: string
  method?: string
  name?: string
  photoUrl?: string
  primaryEmail?: string
  source?: string
  updatedAt?: string
  communityUserId?: string
}

interface AggregatedCommunityUser extends MainSchema.CommunityUser {
  communityNames: string[]
}

export default function PeopleTable() {
  const [people, setPeople] = useState<MainSchema.CommunityUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const columnHelper = createColumnHelper<PersonRow>()
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set())
  const [sorting, setSorting] = useState<SortingState>([])
  const [filterText, setFilterText] = useState('')
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<Set<string>>(
    new Set(),
  )
  const [showCommunityFilter, setShowCommunityFilter] = useState(false)
  const [showMetaMenu, setShowMetaMenu] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(false)
  const [count, setCount] = useState(0)
  const limit = 25
  const client = useApolloClient()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get('taskId')

  const [selectedCommunityUserId, setSelectedCommunityUserId] = useState<
    string | null
  >(null)

  const { data } = useQuery<
    Pick<MainSchema.Query, 'userCommunities'>,
    MainSchema.QueryUserCommunitiesArgs
  >(userCommunitiesQuery, { variables: { page: 0, limit: 100 } })

  const userCommunitiesData = useMemo(
    () => data?.userCommunities?.rows || [],
    [data],
  )

  const communityIds = useMemo(
    () =>
      userCommunitiesData.map(
        (community: MainSchema.Community) => community.id,
      ),
    [userCommunitiesData],
  )

  const { taskUsers } = useTaskUsers(taskId, communityIds)
  const activePeople = taskId ? taskUsers : people
  const isAllCommunitiesSelected = useMemo(() => {
    for (const community of userCommunitiesData) {
      if (!selectedCommunityIds.has(community.id)) return false
    }
    return userCommunitiesData.length > 0
  }, [userCommunitiesData, selectedCommunityIds])

  async function loadUsersPage(page: number) {
    setIsLoading(true)

    try {
      const { data } = await client.query<
        Pick<MainSchema.Query, 'listCommunityUsers'>,
        MainSchema.QueryListCommunityUsersArgs
      >({
        query: listCommunityUsersQuery,
        variables: { communityIds, page, limit },
        fetchPolicy: 'network-only',
      })
      const { listCommunityUsers } = data
      const { communityUsers, count, pages } = listCommunityUsers
      setCount(count)

      if (page === 0) {
        setTotalPages(pages)
        setPeople(communityUsers)
      } else {
        setPeople(prev => [...prev, ...communityUsers])
      }
      setCurrentPage(page + 1)
    } catch (error) {
      let message = _(`error.generic`)

      if (error instanceof Error) {
        message = error.message
      }

      toast.error({
        title: 'Oops...',
        text: `The server returned an error: "${message}"`,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!communityIds.length) return

    setPeople([])
    setCurrentPage(0)
    setTotalPages(0)
    loadUsersPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds])

  const handleRowClick = (rowId: number) => {
    setSelectedRows(prev => {
      const newSelectedRows = new Set(prev)

      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId)
      } else {
        newSelectedRows.add(rowId)
      }

      return newSelectedRows
    })
  }

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allRowIds = new Set(users.map((_, index: number) => index))
      setSelectedRows(allRowIds)
    } else {
      setSelectedRows(new Set())
    }
  }

  const aggregatedUsers = useMemo(() => {
    const grouped = activePeople.reduce(
      (acc, user) => {
        const key = user.email as string

        let communityNames: string[] = []
        if (user.communities && user.communities.length > 0) {
          communityNames = user.communities.map(c => c.name)
        }

        if (acc[key]) {
          acc[key].communityNames = Array.from(
            new Set([...acc[key].communityNames, ...communityNames]),
          )
        } else {
          acc[key] = { ...user, communityNames }
        }
        return acc
      },
      {} as Record<string, AggregatedCommunityUser>,
    )
    return Object.values(grouped)
  }, [activePeople])

  const duplicateCount = useMemo(
    () => people.length - aggregatedUsers.length,
    [people, aggregatedUsers],
  )
  const uniqueTotalCount = useMemo(
    () => count - duplicateCount,
    [count, duplicateCount],
  )

  const filteredUsers = useMemo(() => {
    const lowercasedFilter = filterText.toLowerCase()

    return aggregatedUsers.filter(user => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
      const email = user.email?.toLowerCase() ?? ''
      const matchesText =
        !filterText ||
        fullName.includes(lowercasedFilter) ||
        email.includes(lowercasedFilter)
      const matchesCommunity =
        selectedCommunityIds.size === 0 ||
        (user.communities || []).some(c => selectedCommunityIds.has(c.id))

      return matchesText && matchesCommunity
    })
  }, [aggregatedUsers, filterText, selectedCommunityIds])

  const users = useMemo(() => {
    return filteredUsers.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      primaryEmail: user.email,
      source: user.source,
      createdAt: format(user.createdAt ?? '', 'MM/dd/yyyy'),
      updatedAt: format(user.updatedAt ?? '', 'MM/dd/yyyy'),
      photoUrl: user.photoUrl,
      communities: user.communityNames.join(', '),
      communityUserId: user.communityUserId,
      // method: user.method || '',
    }))
  }, [filteredUsers])

  const selectedRowData = useMemo(
    () => Array.from(selectedRows, rowId => filteredUsers[rowId]),
    [filteredUsers, selectedRows],
  )

  const renderSelectionHeader = useCallback(() => {
    return (
      <SelectionHeader
        allSelected={users.length > 0 && selectedRows.size === users.length}
        onSelectAll={handleSelectAll}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length, selectedRows])

  const renderSelectionCell = useCallback(
    (info: CellContext<PersonRow, unknown>) => (
      <SelectionCell
        rowIndex={info.row.index}
        selected={selectedRows.has(info.row.index)}
        onRowClick={handleRowClick}
      />
    ),
    [selectedRows],
  )

  const renderNameCell = useCallback(
    (info: CellContext<PersonRow, string>) => (
      <NameCell name={info.getValue()} photoUrl={info.row.original.photoUrl} />
    ),
    [],
  )

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'selection',
        header: renderSelectionHeader,
        cell: renderSelectionCell,
        enableSorting: false,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: renderNameCell,
        enableSorting: true,
      }),
      columnHelper.accessor('primaryEmail', {
        header: 'Primary Email',
        enableSorting: true,
      }),
      columnHelper.accessor('communities', {
        header: 'Communities',
        enableSorting: true,
      }),
      columnHelper.accessor('method', {
        header: 'Method',
        enableSorting: true,
      }),
      columnHelper.accessor('source', {
        header: 'Source',
        enableSorting: true,
      }),
      columnHelper.accessor('createdAt', {
        header: 'Created',
        enableSorting: true,
      }),
      columnHelper.accessor('updatedAt', {
        header: 'Updated',
        enableSorting: true,
      }),
    ],
    [renderSelectionHeader, renderSelectionCell, renderNameCell, columnHelper],
  )

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  })

  const handleLoadMore = () => {
    if (!isLoading && currentPage < totalPages) {
      loadUsersPage(currentPage)
    }
  }

  const handleCommunityCheckboxChange = (
    communityId: string,
    checked: boolean,
  ) => {
    setSelectedCommunityIds(prev => {
      const updated = new Set(prev)

      if (checked) {
        updated.add(communityId)
      } else {
        updated.delete(communityId)
      }
      return updated
    })
  }

  const onToggleAllCommunities = (checked: boolean) => {
    setSelectedCommunityIds(
      checked ? new Set(userCommunitiesData.map(comm => comm.id)) : new Set(),
    )
  }

  const handleNameClick = (communityUserId: string) => {
    setSelectedCommunityUserId(communityUserId)
  }

  if (people.length === 0 && isLoading) return <div>Loading...</div>

  if (users.length === 1 && !filterText && !taskId) {
    return <PeopleEmptyState slug={userCommunitiesData[0].slug} />
  }

  return (
    <Wrapper>
      <Heading>
        People {taskId || filterText ? users.length : uniqueTotalCount} total
      </Heading>
      <PeopleTableControls
        filterText={filterText}
        filteredUsers={filteredUsers}
        isAllCommunitiesSelected={isAllCommunitiesSelected}
        selectedCommunityIds={selectedCommunityIds}
        selectedRowData={selectedRowData}
        selectedRows={selectedRows}
        setShowActionsMenu={setShowActionsMenu}
        setShowCommunityFilter={setShowCommunityFilter}
        setShowMetaMenu={setShowMetaMenu}
        showActionsMenu={showActionsMenu}
        showCommunityFilter={showCommunityFilter}
        showMetaMenu={showMetaMenu}
        userCommunitiesData={userCommunitiesData}
        onCommunityCheckboxChange={(communityId, checked) =>
          handleCommunityCheckboxChange(communityId, checked)
        }
        onFilterTextChange={e => setFilterText(e.target.value)}
        onToggleAllCommunities={onToggleAllCommunities}
      />
      <PeopleTableView
        currentPage={currentPage}
        filterText={filterText}
        handleLoadMore={taskId ? null : handleLoadMore}
        handleNameClick={handleNameClick}
        handleRowClick={handleRowClick}
        isLoading={isLoading}
        table={table}
        totalPages={totalPages}
      />
      {selectedCommunityUserId && (
        <ProfilePanelWrapper>
          <ProfilePanel
            communityUserId={selectedCommunityUserId}
            onClose={() => setSelectedCommunityUserId(null)}
          />
        </ProfilePanelWrapper>
      )}
      {!taskId && (
        <>
          1-{Math.min(uniqueTotalCount, currentPage * limit)} of{' '}
          {uniqueTotalCount}
        </>
      )}
    </Wrapper>
  )
}
